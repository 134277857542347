import { navigate } from 'gatsby'
import React from 'react'
import { useQuery } from '@apollo/client'
import { RouteComponentProps } from '@reach/router'
import { capitalize, filter as filterLodash } from 'lodash'
import { CacheUser, OptionType, Order, ResponseType, TableColumnType } from '@types'
import { order as graphqlOrder, user as graphqlUser } from '@graphql'
import { OrderPriceList, StatusIcon } from '@elements'
import { AccountDetailsLayout, AccountLayout, MobileAccountDetailsLayout } from '@layouts'
import { useWindowSize } from '@hooks'
import { getDateISO, zeroPad } from '@utils'
import './../my-sales-history.scss'

interface AccountMySalesHistoryDetailsProps extends RouteComponentProps<{ orderId: string }> {}

const renderStatusIcon = (status: string) => {
  switch (status) {
    case 'PAID':
      return <StatusIcon className={'account-my-sales-history-card-status-icon'} status={'Complete'} />
    case 'CREATED':
      return <StatusIcon className={'account-my-sales-history-card-status-icon'} status={'In Progress'} />
    case 'CANCELED':
      return <StatusIcon className={'account-my-sales-history-card-status-icon'} status={'Canceled'} />
    default:
      return <StatusIcon className={'account-my-sales-history-card-status-icon'} status={'In Progress'} />
  }
}

const AccountMySalesHistoryDetails: React.FC<AccountMySalesHistoryDetailsProps> = props => {
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])
  const { data: userData, loading: userLoading } = useQuery<ResponseType<CacheUser>>(graphqlUser.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const user = userData && userData.res
  const addressCardId = user?.defaultAddressCard?.id

  if (!userLoading && !user) navigate('/home/')
  if (!user) return null

  const { orderId } = props

  const { data: orderData, loading: orderLoading } = useQuery<{ res: Order }>(graphqlOrder.FetchOrder, {
    variables: { id: orderId }
  })

  const backUrl = '/account/store/my-sales-history'
  const order = orderData && orderData.res

  if (!orderLoading && !order) navigate(backUrl)

  const golfItem = order?.golfClub || order?.golfEquipment
  const golfModel = order?.golfClub?.golfClubModel || order?.golfEquipment?.golfEquipmentModel
  const golfType = order?.golfClub?.golfClubType || order?.golfEquipment?.golfEquipmentType
  const status = order?.status

  const columns: TableColumnType[] = [
    {
      title: 'Item',
      key: 'item',
      render() {
        return <>{golfModel?.title || '-'}</>
      }
    },
    {
      title: 'Brand',
      key: 'brand',
      render() {
        return <>{golfItem?.brand?.title || '-'}</>
      }
    },
    {
      title: 'Date',
      key: 'insertedAt',
      render(insertedAt) {
        if (!insertedAt) return ''
        return getDateISO(new Date(insertedAt))
      }
    },
    {
      title: 'Order number',
      key: 'number',
      render(number: number) {
        return number && zeroPad(number, 8)
      }
    },
    {
      title: 'Total',
      key: 'buyerTotalPrice',
      render(buyerTotalPrice: number) {
        return <>{buyerTotalPrice ? `$${buyerTotalPrice}` : '-'}</>
      }
    },
    {
      title: 'Order Status',
      key: 'status',
      render(_, row) {
        const status = row.status
        return renderStatusIcon(status)
      }
    }
  ]

  const renderDetails = () => {
    if (isMobileScreen) {
      const data = [
        {
          label: 'Brand',
          value: golfItem?.brand?.title || '-'
        },
        {
          label: 'Category',
          value: golfItem?.category?.title || '-'
        },
        {
          label: 'Release Year',
          value: (golfModel?.releaseYear || '-').toString()
        },
        {
          label: 'Flex',
          value: capitalize(order?.golfClub?.golfClubType?.flex) || false
        },
        {
          label: 'Dexterity',
          value: capitalize(order?.golfClub?.golfClubType?.dexterity) || false
        },
        {
          label: 'Condition',
          value: capitalize(golfType?.condition) || '-'
        },
        {
          label: 'option',
          value: golfType?.option || '-'
        }
      ]

      const filteredData = filterLodash(data, o => o.value) as OptionType[]

      return (
        <MobileAccountDetailsLayout
          status={status || ''}
          avatar={golfModel?.avatar || ''}
          title={golfModel?.title || ''}
          backLink={'/account/store/my-sales-history'}
          backLinkTitle={'My Sales History'}
          data={filteredData}
        >
          <div className={'account-my-golf-club-inventory-details-footer'}>
            <div>
              <OrderPriceList addressCardId={addressCardId} price={order?.itemPrice} />
              {/* add golfClub price without double counting taxes*/}
            </div>
            <div className={'account-my-golf-club-inventory-details-footer-status'}>
              <div className={'uppercase'}>
                <strong>Status</strong>
              </div>
              <div>{renderStatusIcon(status || '')}</div>
            </div>
          </div>
        </MobileAccountDetailsLayout>
      )
    }

    return (
      <AccountDetailsLayout
        title={'My Sales History'}
        details={{
          title: 'Item',
          text: golfModel?.title + ' ' + golfItem?.brand.title
        }}
        backUrl={backUrl}
        avatar={golfModel?.avatar || ''}
        columns={columns}
        data={order}
        loading={userLoading || orderLoading}
      >
        <div className={'account-my-golf-club-inventory-details-footer'}>
          <div>
            <OrderPriceList addressCardId={addressCardId} price={order?.itemPrice} />
            {/* add golfClub price without double counting taxes*/}
          </div>
          <div className={'account-my-golf-club-inventory-details-footer-status'}>
            <div className={'uppercase'}>
              <strong>Status</strong>
            </div>
            <div>{renderStatusIcon(status || '')}</div>
          </div>
        </div>
      </AccountDetailsLayout>
    )
  }

  return (
    <AccountLayout pageClassName={'my-sales-history'} hideLayout={isMobileScreen}>
      {renderDetails()}
    </AccountLayout>
  )
}

export default AccountMySalesHistoryDetails
